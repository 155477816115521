<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-10">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
            {{ lista_campos != "" ? "Editar" : "Crear" }}
            {{ $t("ATENDIMENTO.NOME") }}
          </h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form">
              <div class="form-group row">
                <div class="col-md-6">
                  <label class="col-md-12">Seleccione Paciente :*</label>
                  <b-input-group class="mb-2 mr-sm-2 mb-sm-0 bg-secondary">
                    <input required type="text" class="form-control" v-model="nome_pesquisa" />
                    <b-button v-b-modal.SelecionarPaciente class="btn btn-indo" @click="pesquisarPaciente()">
                      <i class="fa fa-search search-icon m-0 p-0"></i>
                    </b-button>
                  </b-input-group>

                  <div>
                    <b-modal size="lg" id="SelecionarPaciente" hide-footer hide-header title="SelecionarPaciente"
                      ref="modal_paciente">
                      <div class="row">
                        <div class="col-md-6">
                          <input required type="text" class="form-control" v-model="nome_pesquisa" />
                        </div>
                        <div class="col-md-3">
                          <input required type="date" class="form-control" v-model="data_nascimento" />
                        </div>
                        <div class="col-md-3">
                          <treeselect :options="filtro_filial" label="filial" placeholder="Seleccione una o más(s)..."
                            v-model="filial_id" />
                        </div>
                      </div>

                      <b-table :fields="['nome_sobrenome', 'rg', 'data_nascimento', 'acoes']" :items="lista_usuarios"
                        :per-page="perPage" :current-page="currentPage" id="atendimento-table" class="table table-hover"
                        show-empty empty-text="Nenhum registro encontrado!">
                        <template #cell(acoes)="{ item }">
                          <button @click="selecionarPaciente(item)" class="btn btn-light mx-1" v-b-tooltip.hover
                            title="Clique para selecionar paciente">
                            Selecionar
                          </button>
                        </template>
                        <template #cell(data_nascimento)="{ item }">
                          {{ formatarData(item.data_nascimento) }}
                        </template>
                      </b-table>
                      <b-pagination v-model="currentPage" :total-rows="lista_usuarios.length" :per-page="perPage"
                        aria-controls="atendimento-table">
                      </b-pagination>
                    </b-modal>
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="col-md-12">Tipo:*</label>
                  <select v-model="form.tipo_atendimento_id" class="form-control">
                    <option value="1">Consulta</option>
                    <option value="2">Retorno</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-3">
                  <label class="col-md-12"> Fecha:*</label>
                  <input required type="date" class="form-control" v-model="form.data" />
                </div>
                <div class="col-md-2">
                  <label class="col-md-12"> Hora:*</label>
                  <input required type="time" class="form-control" v-model="form.hora" />
                </div>
                <div class="col-md-7">
                  <label class="col-md-12">Vincular Consultorio:</label>
                  <treeselect :multiple="false" :options="lista_vinculo_especialidade"
                    placeholder="Selecione un consutorio..." v-model="form.vinculo_sala_especialidade_id" />

                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-md-12"> Descripción:</label>
                  <input required type="text" class="form-control" v-model="form.descricao" placeholder="..." />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-md-12">
                    <b-form-checkbox id="checkbox" v-model="form.triagem" name="checkbox-1">
                      <b> Debe pasar a triaje
                      <i class="far fa-question-circle text-gray" v-b-tooltip.hover
                        title="Indica si el paciente debe ir automáticamente a la cola de triaje o, si no está marcado, debe ir directamente a la cola de consultas">
                      </i></b> 
                    </b-form-checkbox>
                  </label>

                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12 text-center">
                  <button @click.prevent="
                    confirm(lista_campos == '' ? 'crea' : 'Edita')
                    " class="btn btn-primary" :disabled="verif">
                    Salvar
                    <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
$(document).ready(function () {
  $("#estado").select2();
});
</script>
<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { getFilialId } from "../../../core/services/filial.service";
export default {
  mixins: [fireAlert],

  data() {
    return {
 
      nome_pesquisa: "",
      data_nascimento: null,
      filial_id: getFilialId(),
      disableSaveModal: false,
      usuario_id: null,
      form: {
        usuario_id: null,
        status: 1,
        descricao: "",
        data: "",
        hora: "",
        funcionario_id: null,
        tipo_atendimento_id: null,
        vinculo_sala_especialidade_id: null,
        triagem: true
      },
      filtro_filial:[{
        id:0,
        label: "Todas las filiales"
      }],
      verif: false,
      option: "",
      currentPage: 1,
      perPage: 7,
    };
  },
  watch: {
    nome_pesquisa() {
      if (this.nome_pesquisa.length >= 3) {
       this.pesquisarPaciente()
      }
    },
    data_nascimento(){
      this.pesquisarPaciente()
    }
  },
  created() {
    this.listar_requerimentos();
    this.preenxerCampos();

  },
  computed: {
    lista_vinculo_especialidade() {
      return this.$store.state.configEmpresa.lista_vinculo_especialidade;
    },
    
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.atendimento.mensagem_alert;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },
  },
  methods: {
    async pesquisarPaciente() {   
      var p = { pesquisa: this.nome_pesquisa, perfil_id: 3,data_nascimento:this.data_nascimento, filial_id:this.filial_id };
      await this.$store.dispatch("configUsuarios/buscar_usuarios", p);
    },

    async selecionarPaciente(value) {
      this.form.usuario_id = value.id;
      this.nome_pesquisa = value.nome;
      this.$refs["modal_paciente"].hide();
    },

    async listar_requerimentos() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configEmpresa/list_vinculo_sala_especialidade_ativos");
      await this.$store.dispatch("configEmpresa/listar_filial");
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      this.lista_filials.forEach(e => {
        if(e.id == this.filial_id){
          this.filtro_filial.push(e)
        }
      });
    },

     formatarData(value){
      var dataNascimento = new Date(value);
      return dataNascimento.toLocaleDateString()
    },

    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` un atendimento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "crea") this.create();
            if (tipo == "Edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      console.log(this.form)
      if (this.form.usuario_id) {
        await this.$store
          .dispatch("atendimento/create_atendimento", this.form)
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"

            this.fireAlert(this.mensagem_alert);
            this.$router.push({
              name: "atendimento",
              // params: {
              //   atendimento_id: this.$store.state.atendimento.atendimento_id,
              // },
            });
          });
      } else {
        this.fireAlert({
          title:
            "¡No hay paciente seleccionado! Haga un clic en el icono de búsqueda y seleccione un paciente primero.",
          tipo: "error",
          timer: 5000,
        });
      }

      this.verif = false;
    },
    async update() {
      console.log('foi')
      this.verif = true;
      await this.$store.dispatch("atendimento/update_atendimento", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "atendimento",
      });
    },

    preenxerCampos() {
      if (this.lista_campos != ""){
        this.selectedUser = this.lista_campos.paciente
        this.form.id = this.lista_campos.id
        this.form.usuario_id = this.lista_campos.usuario_id
        this.form.descricao = this.lista_campos.descricao
        this.form.funcionario_id = this.lista_campos.funcionario_id
        this.form.vinculo_sala_especialidade_id = this.lista_campos.vinculo_sala_especialidade_id
        this.form.status = this.lista_campos.status
        this.form.data = this.lista_campos.data
        this.form.hora = this.lista_campos.hora
        this.form.tipo_atendimento_id = this.lista_campos.tipo_atendimento_id
        this.nome_pesquisa = this.lista_campos.paciente
      }
    },
     

  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>